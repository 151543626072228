import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Amplify from 'aws-amplify'
import amplifyConfig from '../utils/amplifyConfig'


Amplify.configure(amplifyConfig)


export default () => {
    useEffect(() => {
        navigate('/projects')
    }, [])

    return <div/>
}
